(function($){
	$(document).ready(function() {
		var is_member_login = $('#MemberID').text();
		var LoggedInUserStringId = $('#LoggedInUserStringId').text();
		if ($("body").hasClass("iai-multilogin")) {
			if(is_member_login > 0){
				setInterval(function() {
				var baseHref = $('base').attr('href');
				var stringId = LoggedInUserStringId;
				window.localStorage.setItem('userStringId', stringId);
					$.ajax({
						url:baseHref + 'user-logged-in/checkLoggedUser',
						type: "POST",
						data : {userStringId:stringId},
						success: function(response) {
							console.log(response);
							var resp = JSON.parse(response)
							if(resp.response == false){
								window.location.href = baseHref + 'other-login';
							}
						}
					});
				},900000);
			}
		}
	});
})(jQuery);